exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-business-demo-tsx": () => import("./../../../src/pages/business/demo.tsx" /* webpackChunkName: "component---src-pages-business-demo-tsx" */),
  "component---src-pages-business-index-tsx": () => import("./../../../src/pages/business/index.tsx" /* webpackChunkName: "component---src-pages-business-index-tsx" */),
  "component---src-pages-chatterconf-tsx": () => import("./../../../src/pages/chatterconf.tsx" /* webpackChunkName: "component---src-pages-chatterconf-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-language-tsx": () => import("./../../../src/pages/learn-language.tsx" /* webpackChunkName: "component---src-pages-learn-language-tsx" */),
  "component---src-pages-lessons-index-tsx": () => import("./../../../src/pages/lessons/index.tsx" /* webpackChunkName: "component---src-pages-lessons-index-tsx" */),
  "component---src-pages-lessons-pricing-tsx": () => import("./../../../src/pages/lessons/pricing.tsx" /* webpackChunkName: "component---src-pages-lessons-pricing-tsx" */),
  "component---src-pages-lessons-vouchers-checkout-tsx": () => import("./../../../src/pages/lessons/vouchers/checkout.tsx" /* webpackChunkName: "component---src-pages-lessons-vouchers-checkout-tsx" */),
  "component---src-pages-lessons-vouchers-index-tsx": () => import("./../../../src/pages/lessons/vouchers/index.tsx" /* webpackChunkName: "component---src-pages-lessons-vouchers-index-tsx" */),
  "component---src-pages-live-language-tsx": () => import("./../../../src/pages/live/language.tsx" /* webpackChunkName: "component---src-pages-live-language-tsx" */),
  "component---src-pages-streams-index-tsx": () => import("./../../../src/pages/streams/index.tsx" /* webpackChunkName: "component---src-pages-streams-index-tsx" */),
  "component---src-pages-streams-pricing-tsx": () => import("./../../../src/pages/streams/pricing.tsx" /* webpackChunkName: "component---src-pages-streams-pricing-tsx" */),
  "component---src-pages-streams-subscription-existing-user-tsx": () => import("./../../../src/pages/streams-subscription/existing-user.tsx" /* webpackChunkName: "component---src-pages-streams-subscription-existing-user-tsx" */),
  "component---src-pages-streams-subscription-instructions-tsx": () => import("./../../../src/pages/streams-subscription/instructions.tsx" /* webpackChunkName: "component---src-pages-streams-subscription-instructions-tsx" */),
  "component---src-pages-streams-subscription-login-tsx": () => import("./../../../src/pages/streams-subscription/login.tsx" /* webpackChunkName: "component---src-pages-streams-subscription-login-tsx" */),
  "component---src-pages-streams-subscription-pricing-plans-tsx": () => import("./../../../src/pages/streams-subscription/pricing-plans.tsx" /* webpackChunkName: "component---src-pages-streams-subscription-pricing-plans-tsx" */),
  "component---src-pages-streams-subscription-signup-tsx": () => import("./../../../src/pages/streams-subscription/signup.tsx" /* webpackChunkName: "component---src-pages-streams-subscription-signup-tsx" */),
  "component---src-pages-streams-vouchers-checkout-tsx": () => import("./../../../src/pages/streams/vouchers/checkout.tsx" /* webpackChunkName: "component---src-pages-streams-vouchers-checkout-tsx" */),
  "component---src-pages-streams-vouchers-index-tsx": () => import("./../../../src/pages/streams/vouchers/index.tsx" /* webpackChunkName: "component---src-pages-streams-vouchers-index-tsx" */),
  "component---src-pages-vouchers-redeem-tsx": () => import("./../../../src/pages/vouchers/redeem.tsx" /* webpackChunkName: "component---src-pages-vouchers-redeem-tsx" */)
}

