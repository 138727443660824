import { GiftCardProps } from 'src/components/GiftCard/GiftCard'
import theme from 'src/lib/theme'

export enum LanguageCode {
  en = 'en',
  de = 'de',
  es = 'es',
  fr = 'fr',
}

export enum SiteLocale {
  en = 'en',
  de = 'de',
  es = 'es',
}

export type PolyglotFn = (key: string) => string

export enum ProductType {
  streams = 'streams', //'lessons' in the future if necessary
}

export type PromoPageData = {
  locale: SiteLocale
  product: ProductType
  slug: string
  headline: string
  image: string
  ctaLabel: string
  ctaLink: string
  socialTitle: string
  socialDescription: string
  socialImage: string
  endAt?: string
}

export type LocalizedData = {
  locale: SiteLocale
  text: string
}

export type PageContext = {
  l2Code?: LanguageCode // for /learn-<language> pages
  locale: SiteLocale
  localeResource: {
    [key: string]: string
  }
  canonicalPath: string
  promoPageData?: PromoPageData
}

export type CustomTheme = typeof theme

export enum NavigationItemName {
  whyChatterbug = 'why_chatterbug',
  whyStreams = 'why_streams',
  compareChatterbug = 'compare_chatterbug',
}

export enum OrgItemName {
  products = 'products',
  moreProducts = 'more_products',
  news = 'news',
}

export enum GiftsNavigationItemName {
  howItWorks = 'how_it_works',
  vouchers = 'vouchers',
  redeem = 'redeem',
}

export enum StreamsNavigationItemName {
  features = 'features',
  streamers = 'streamers',
  pricing = 'pricing',
  video = 'video',
  vouchers = 'vouchers',
}

export enum LessonsNavigationItemName {
  lessonsFeatures = 'lessonsFeatures',
  method = 'method',
  inAction = 'in_action',
  students = 'students',
  tutors = 'tutors',
}

export enum BusinessNavigationItemName {
  features = 'features',
  compare = 'compare',
  howItWorks = 'howItWorks',
  learning = 'learning',
  pricing = 'pricing'
}

export type LanguagePair = {
  l1Code: SiteLocale
  l2Codes: LanguageCode[]
}

export type LanguagePairLearningLanguage = {
  l2Code: LanguageCode
  seoPath: string
  grammarPath?: string
  mediaPath?: string
}

export type LanguagePairInJson = {
  l1Code: SiteLocale
  l2Codes: LanguagePairLearningLanguage[]
}

export type LanguagePairsJson = {
  nodes: LanguagePairInJson[]
}

export type TestimonialData = {
  fullName: string
  image: string
  l2Code: LanguageCode
  cefrLevel: CefrLevel
  hours: number
  localizedTestimonial: LocalizedData[]
  activeLocales: SiteLocale[]
  orderByDesc: number
}

export type TestimonialsJSON = {
  nodes: TestimonialData[]
}

export type TutorData = {
  fullName: string
  image: string
  fluentLanguages: LanguageCode[]
  nativeLanguages: LanguageCode[]
  teachesLanguages: LanguageCode[]
  localizedBio: LocalizedData[]
  activeLocales: SiteLocale[]
  orderByDesc: number
}

export type TutorsJSON = {
  nodes: TutorData[]
}

export type CBLiveEventData = {
  name: string
  image: string
  localizedTitle: LocalizedData[]
  localizedWhen: LocalizedData[]
  localizedDescription: LocalizedData[]
  l2Code: LanguageCode
  orderByDesc: number
  hidden?: boolean
}

export type CBLiveEventsJSON = {
  nodes: CBLiveEventData[]
}

export type CBLivePageData = {
  l2Code: LanguageCode
  socialImage: string
  localizedMainHeader: LocalizedData[]
  localizedSubHeader: LocalizedData[]
  localizedDescription: LocalizedData[]
}

export type CBLivePagesJSON = {
  nodes: CBLivePageData[]
}

export type BlogPost = {
  id: string
  title: string
  link: string
  imageUrl: string
  description: string
}

export type BlogPostsJSON = {
  nodes: BlogPost[]
}

export enum Product {
  streams = 'streams',
  lessons = 'lessons',
}

export type DropdownItem<T> = { value: T; label: string; icon?: JSX.Element }

export type LanguageDropDownItem = DropdownItem<LanguageCode>

export type SiteLocaleDropDownItem = DropdownItem<SiteLocale>

export type ProductDropDownItem = DropdownItem<Product>

export type NavigationItem = {
  label: string
  name: string
  path: string
} | null

export type SecondaryNavigationGroup = {
  title?: string
  items: NavigationItem[]
}

export enum CefrLevel {
  A1 = 'a1',
  A2 = 'a2',
  B1 = 'b1',
  B2 = 'b2',
  C1 = 'c1',
  C2 = 'c2',
}

export enum CompareTableValue {
  lightCheck = 'lightCheck',
  check = 'check',
  cross = 'cross',
}

export enum GiftStepValue {
  choose = 'choose',
  share = 'share',
  start = 'start',
}

export interface LessonProductItem {
  label: string
  name: string
  icon: string
  image?: string
  price: number
  numberOfLessons: number
  pricePerExtraLesson?: number
  isPopular?: boolean
}

export interface GiftProductItem extends LessonProductItem {
  funName: string
  funDescription: string
  productId: string
  giftSize: GiftCardProps['giftSize']
  product: Product
}
export interface StreamProductItem {
  label: string
  icon: string
  price: number
  billOccurenceInMonths: 1 | 6 | 12
  planIds: Record<Currency, string>
  isPopular?: boolean
}

export interface VoucherData {
  canBeRedeemed: boolean
  title: string
  units: number
  purchaserName: string
  recipientName: string
  code: string
  message: string
  subTitle: string
  product: Product
}

export type ChatterconfFeaturedCompany = {
  companyName: string
  companyLogo: string
  orderByDesc: number
}

export type ChatterconfSpeaker = {
  speakerName: string
  speakerPhoto: string
  companyName: string
  companyBio?: string
  companyUrl: string
  companyLogo?: string
  shortBio: string
  longBio: string
  orderByDesc: number
}

export type ChatterconfSpeakersSection = {
  name: string
  list: ChatterconfSpeaker[]
}

export type ChatterconfPresentation = {
  speakerName: string
  speakerPhoto: string
  companyName: string
  companyLogo?: string
  companyUrl?: string
  title: string
  description: string
  orderByDesc: number
}

export type ChatterconfPresentationsSection = {
  name: string
  title: string
  subtitle: string
  ctaLabel: string
  ctaLink: string
  list: ChatterconfPresentation[]
}

export type ChatterconfRoom = {
  title: string
  description: string
  image: string
  orderByDesc: number
}

export type ChatterconfRoomsSection = {
  name: string
  isHidden: boolean
  list: ChatterconfRoom[]
}

export type ChatterconfBottomSection = {
  label: string
  ctaLabel: string
  ctaLink: string
}

export type ChatterconfPage = {
  year: string
  date: string
  subtitle: string
  ctaLabel: string
  ctaLink: string
  socialImage: string
  socialDescription: string
  logoImage: string
  featuredCompanies: ChatterconfFeaturedCompany[]
  speakersSection: ChatterconfSpeakersSection
  presentationsSection: ChatterconfPresentationsSection
  roomsSection: ChatterconfRoomsSection
  bottomSection: ChatterconfBottomSection
}

export type ChatterconfPagesJson = {
  nodes: ChatterconfPage[]
}

export type GiftVoucherTheme = 'easter' | 'default' | 'xmas'

export enum PageAccentVariant {
  blue = 'blue',
  pink = 'pink',
  gradient = 'gradient',
}

export type PageThemeContext = {
  accentColor: string
  accentGradient?: string,
  accentHoverColor?: string
  accentVariant?: PageAccentVariant
  navigationItemAccentColor?: string
  sellingPointAccentColor?: string
  extraLinkAccentColor?: string
  extraLinkAccentHoverColor?: string
}

export enum Currency {
  eur = 'eur',
  usd = 'usd',
}
